.cls-1 {
    isolation: isolate;
  }

  .cls-2 {
    opacity: 0.14;
    mix-blend-mode: multiply;
  }

  .cls-13,
  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #ededed;
  }

  .cls-5 {
    fill: #ed9870;
  }

  .cls-6 {
    fill: #9c9897;
  }

  .cls-7 {
    font-size: 16.6px;
    font-family: 'Raleway-Black', 'Raleway';
  }

  .cls-10,
  .cls-13,
  .cls-7,
  .cls-8 {
    font-weight: 800;

  }

  .cls-8 {
    font-size: 10px;
    font-family: 'Raleway-Heavy', 'Raleway';
  }

  .cls-10,
  .cls-8 {
    fill: #0c3944;
  }

  .cls-9 {
    fill: #edeff3;
  }

  .cls-10 {
    font-size: 16px;
  }

  .cls-10,
  .cls-13 {
    font-family: 'Raleway-ExtraBold', 'Raleway';
  }

  .cls-11 {
    font-family: 'Raleway';
    font-weight: 400;
  }

  .cls-12 {
    fill: #196c84;
  }

  .cls-13 {
    font-size: 8.02px;
  }