/* body {
  font-family: "Roboto", sans-serif;
} */


#page {
  min-height: 100vh; /* 1 */
  display: flex; /* 2 */
  flex-direction: column; /* 3 */
}

main {
  height: calc(100vh - 120px);
  position: relative;
}

.admin-container {
}

.sub-menu-link {
  color: inherit;
  text-decoration: none;
}

footer {
  height: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: yellow;
}

.cropper-canvas {
  border: 2px solid #000;
}

.modal-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  z-index: 1000;
  display: none;
  align-items: center;
}
.modal-wrapper.visible {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  right: 18px;
  top: 18px;
}

.close-btn {
  height: 20px;
  width: 20px;
}

.modal {
  width: 80%;
  height: 80%;
  background-color: #fff;
  margin: 0px auto;
  padding: 48px;
  position: relative;
  border-radius: 4px;
}

.test-item {
  border: 2px solid red;
  height: 100px;
}

.crop-app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 200px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

/* interface edition */

.editor-content {
  height: 30px;

  background-color: transparent;
  box-sizing: border-box;
  color: white;
  font-size: 14px;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: -30px;
  text-align: left;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.editor-content-open {
  margin-top: 0px;
  height: auto;

  transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.editor-content-text {
  padding: 15px 0px;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all 0.2s ease-in;
}

.editor-content-image {
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all 0.2s ease-in;
}

.editor-content-image-open {
  visibility: visible;
  opacity: 1;
  transition: all 0.8s ease-in;
}

.editor-content-text-open {
  visibility: visible;
  opacity: 1;
  transition: all 0.8s ease-in;
}

.fa-angle-down-menu {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.6s cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.fa-rotate-180 {
  color: rgba(255, 255, 255, 1);
}

.arrow-wrapper {
}

/* quill editor */
.ql-toolbar {
  background-color: #fbf68f;
  border-radius: 4px 4px 0 0;
}

.ql-editor {
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

.ql-editor strong,
.rendered-html strong {
  font-weight: 900;
}

/*mjml style */

@media only screen and (min-width: 480px) {
  .mj-column-per-33-333333333333336 {
    width: 33.333333333333336% !important;
    max-width: 33.333333333333336%;
  }
}

@media only screen and (min-width: 480px) {
  .mj-column-per-50 {
    width: 50% !important;
    max-width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  table.mj-full-width-mobile {
    width: 100% !important;
  }
  td.mj-full-width-mobile {
    width: auto !important;
  }
}

@media only screen and (max-width: 480px) {
  table.mj-full-width-mobile {
    width: 100% !important;
  }
  td.mj-full-width-mobile {
    width: auto !important;
  }
  .content-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (min-width: 480px) {
  .mj-column-px-326 {
    width: 326px !important;
    max-width: 326px;
  }

  .mj-column-per-45 {
    width: 45% !important;
    max-width: 45%;
  }
  .mj-column-per-10 {
    width: 10% !important;
    max-width: 10%;
  }
  .mj-column-per-48 {
    width: 48% !important;
    max-width: 48%;
  }
  .mj-column-per-4 {
    width: 4% !important;
    max-width: 4%;
  }

  .mj-column-per-44 {
    width: 44% !important;
    max-width: 44%;
  }
  .mj-column-per-6 {
    width: 6% !important;
    max-width: 6%;
  }
  .mj-column-per-100 {
    width: 100% !important;
    max-width: 100%;
  }
  .mj-column-per-30 {
    width: 30% !important;
    max-width: 30%;
  }

  .mj-column-per-64 {
    width: 64% !important;
    max-width: 64%;
  }
  .mj-column-per-50 {
    width: 50% !important;
    max-width: 50%;
  }
  .mj-column-px-600 {
    width: 600px !important;
    max-width: 600px;
  }
  .mj-column-px-580 {
    width: 580px !important;
    max-width: 580px;
  }

  .mj-column-per-36 {
    width: 36% !important;
    max-width: 36%;
  }
  .mj-column-per-58 {
    width: 58% !important;
    max-width: 58%;
  }

  .mj-column-per-3 {
    width: 3% !important;
    max-width: 3%;
  }
  .mj-column-per-53 {
    width: 53% !important;
    max-width: 53%;
  }
}
